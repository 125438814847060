.announcement-container {
    margin-top: 165px;
    background-color: #F5F5F5;
}

.announcement-title {
    margin-left: 100px;
    margin-top: 60px;
    margin-bottom: 75px;
    display: flex;
    justify-content: space-between;
    z-index: 1;
}

.date-title {
    font-size: 45px;
    font-weight: 500;
    letter-spacing: 3px;
}

.button-down-container {
    margin-right: 140px;
    margin-top: 8px;
}

.date-button {
    font-size: 13px;
    font-weight: 450;
    letter-spacing: 1px;
    background-color: white;
    width: 168px;
    height: 43px;
    border: 2.4px solid #a2a2a2;
    border-radius: 10px;
}

.date-button:hover {
    background-color: #f0f0f0;
}

.arrow-down {
    margin-left: 13px;
}


@media (max-width: 1024px) {
    .announcement-container {
        margin-top: 135px;
    }

    .ellipse-3 {
        height: 480px;
        margin-left: -200px;
        margin-top: -220px;
        z-index: -1;
    }
    
    .announcement-title {
        margin-left: 0;
        margin-top: 60px;
        margin-bottom: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .date-title {
        font-size: 37px;
        text-align: center;
    }
    
    .button-down-container {
        margin-right: 0px;
        margin-top: 5px;
    }
    
    .date-button {
        font-size: 13px;
        font-weight: 450;
        letter-spacing: 1px;
        background-color: white;
        width: 158px;
        height: 36px;
        border: 2.4px solid #a2a2a2;
        border-radius: 10px;
    }
    
    .date-button:hover {
        background-color: white;
    }
    
    .arrow-down {
        margin-left: 5px;
    }
    
    .dropdown-content {
        width: 158px;
    }
     
}

@media (max-width: 600px) {
    .announcement-container {
        margin-top: 105px;
    }


    .ellipse-3 {
        display: none;
    }
    
    .announcement-title {
        margin-top: 100px;
        margin-bottom: 50px;
        padding: 0px 20px;
        flex-wrap: wrap;
    }
    
    .date-title {
        font-size: 28px;
        letter-spacing: 0;
    }
    
    .date-button {
        font-size: 12px;
        width: 148px;
        height: 30px;
        border: 2px solid #a2a2a2;
        border-radius: 7px;
    }
    
    .dropdown-content {
        width: 148px;
        border: 2px solid #a2a2a2;
        border-radius: 7px;
    }
}