html {
    background-color: #F5F5F5;
}

.admin-container {
    display: flex;
}

.admin-announcement-title-1 {
    display: none;
}

.admin-container-2 {
    padding-top: 100px;
    width: 100%;
    background-color: #F5F5F5;
}

.ant-input:focus, .ant-input:hover, .ant-picker:focus, .ant-picker:hover {
    border-color: black;
    box-shadow: 0px 0px 2px #888888;
}


.admin-navbar {
    display: none;
    flex-direction: column;
    width: 4%;
    max-height: 100%;
    background-color: var(--black);
    align-items: center;
    overflow-y: auto;
    padding: 0px 10px;
    z-index: 10;
}

.admin-home-icon {
    margin-top: 10vh;
    margin-bottom: 30px;
    width: 100%;
}

.admin-announcement-icon {
    width: 100%;
}

.admin-home-icon:hover, .admin-announcement-icon:hover {
    width: 105%;
}

.admin-announcement-header {
    display: flex;
    position: fixed;
    justify-content: space-between;
    top: 0;
    align-items: center; 
    background-color: var(--light-green);
    color: white;
    height: 60px;
    width: 100%;
    padding: 0 3% 0 3%;
    margin-bottom: 40px;
}

.admin-announcement-title {
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 2px;
    margin-top: 10px;
}

.logoff-button {
    background-color: var(--black);
    color: white;
    height: 60px;
    width: 100px;
    border: none;
    font-size: 14px;
}

.logoff-button:hover {
    font-size: 15px;
    cursor: pointer;
}

.admin-buttons-container {
    display: flex;
    width: 69%;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 30px;
}

.ant-dropdown-menu-title-content {
    color: var(--light-gray);
}

.admin-dropdown-content {
    font-size: 14px;
    text-decoration: none;
}

.admin-add-button {
    background-color: #02783F;
    color: white;
    width: 150px;
    height: 43px;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
}

.admin-announcements {
    display: flex;
    flex-direction: column;
    width: 69%;
    margin: auto;
}

.admin-event {
    .event-container {
        height: 200px;
        margin-left: 0px;
        margin-bottom: 30px;
        border: 1px solid rgba(0, 0, 0, 0.316);
        border-radius: 10px;
        padding: 0px;
        background-color: white;

        /* Add drop shadow and transition */
        transition: box-shadow 0.3s ease, transform 0.17s ease-in;

        /* Add hover effect */
        &:hover {
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
            cursor: pointer;
            /* transform: scale(1.008); */
            z-index: 0;
        }
    }

    .read-button, .line {
        display: none;
    }

    .event-photo {
        min-width: 270px;
        max-width: 270px;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
        object-position: 50% 50%;
    }

    .event-about {
        margin-left: 25px;
    }

    .event-paragraph {
        width: 95%;
        margin-top: 15px;
        font-size: 18px;
    }

    .event-main-title {
        margin-top: 15px;
        font-size: 20px;
    }

    .event-second-title {
        font-size: 15px;
    }

    .event-date-container{
        margin-top: 2px;
    }
}

.admin-nav-buttons {
    display: flex;
    gap: 62%;
    justify-content: center ;
    margin-bottom: 50px;
}

.admin-button {
    border: none;
    background-color: #F5F5F5;
    color: var(--light-gray);
}

.no-data-container {
    text-align: center;
    padding-top: 6vh;
}

.no-data-image {
    width: 20vw;
}

@media (max-width: 1025px) {
    .admin-navbar {
        width: 6%;
    }
    
    .admin-home-icon {
        margin-top: 8vh;
        width: 95%;
    }

    .admin-container-2{
        padding-top: 85px;
    }
    
    .admin-announcement-icon {
        width: 95%;
    }
    
    .admin-home-icon:hover, .admin-announcement-icon:hover {
        width: 100%;
    }
    
    .admin-announcement-header {
        height: 50px; 
    }
    
    .admin-announcement-title {
        font-size: 21px;
    }
    
    .logoff-button {
        height: 50px;
        width: 80px;
        font-size: 13px;
    }
    
    .logoff-button:hover {
        font-size: 14px;
    }

    .admin-buttons-container {
        width: 90%;
    }
    
    .admin-add-button {
        height: 37px;
    }
    
    .admin-announcements {
        display: flex;
        flex-direction: column;
        width: 90%;
    }
    
    .admin-event {
        .event-container {
            height: 170px;
            margin-bottom: 20px;
            flex-direction: row;
            justify-content: flex-start;
        }
    
        .event-photo {
            min-width: 240px;
            max-width: 240px;
        }
    
        .event-about {
            margin-left: 15px;
            align-items: flex-start;
        }
    
        .event-paragraph {
            width: 98%;
            margin-top: 15px;
            font-size: 13px;
            text-align: left;
            margin-bottom: 0px;
        }
    
        .event-main-title {
            margin-top: -10px;
            font-size: 19px;
        }
    
        .event-second-title {
            font-size: 14px;
        }
    
        .event-date-container{
            margin-top: 0px;
        }

        .event-date{
            font-size: 12px;
        }
    }
    
    .admin-nav-buttons {
        gap: 75%;
    }
}

@media (max-width: 900px) {
    .admin-navbar {
        width: 7%;
    }
    
    .admin-home-icon {
        margin-top: 12vh;
    }

    .admin-container-2{
        padding-top: 75px;
    }

    .admin-announcement-header {
        height: 40px; 
    }
    
    .admin-announcement-title {
        font-size: 18px;
    }

    .logoff-button {
        height: 40px;
        width: 70px;
        font-size: 11px;
    }
    
    .logoff-button:hover {
        font-size: 12px;
    }
    
    .admin-buttons-container {
        width: 93%;
    }
    
    .admin-announcements {
        width: 93%;
    }
    
    .admin-event {
        .event-container {
            margin-bottom: 10px;
        }
    
        .event-photo {
            min-width: 240px;
        }
    
        .event-about {
            margin-left: 12px;
        }
    
        .event-paragraph {
            width: 98%;
            margin-top: 10px;
            font-size: 12px;
        }
    
        .event-main-title {
            margin-top: 0px;
            font-size: 19px;
        }
    
        .event-second-title {
            font-size: 13px;
        }
    
        .event-date-container{
            margin-top: -1px;
            margin-bottom: 5px;
        }

        .event-date{
            font-size: 11px;
        }
    }
    
    .admin-nav-buttons {
        gap: 70%;
    }
}

@media (max-width: 600px) {
    .admin-container{
        flex-direction: column;
    }

    .admin-announcement-title-1 {
        display: flex;
    }

    .admin-announcement-header { 
        padding: 10px 10px 10px 20px;
        height: 46px;
    }

    .logoff-button {
        height: 46px;
        width: 70px;
        font-size: 12px;
    }

    .admin-container-2 {
        padding: 90px 0 20px 0;
    }
    
    .admin-home-icon {
        margin-top: 30px;
        width: 20px;
    }
    
    .admin-announcement-icon {
        width: 25px;
        margin-right: -5px;
        margin-left: 15px;
    }
    
    .admin-home-icon:hover, .admin-announcement-icon:hover {
        width: 25px;
    }
    
    .admin-buttons-container {
        width: 90%;
    }
    
    .admin-add-button {
        height: 30px;
    }
    
    .admin-announcements {
        display: flex;
        flex-direction: column;
        width: 90%;
    }
    
    .admin-event {
        .event-container {
            height: 450px;
            margin-bottom: 20px;
            flex-direction: column;
            padding: 15px 10px 10px 10px;
        }
    
        .event-photo {
            width: 240px;
            min-height: 180px;
            max-height: 180px;
        }
    
        .event-about {
            margin-top: 10px;
            align-items: center;
        }

        .event-main-title {
            margin-top: 0px;
            font-size: 18px;
        }
    
        .event-second-title {
            font-size: 14px;
            margin-top: 0px;
        }
    
        .event-date-container{
            margin-top: -1px;
            margin-bottom: 5px;
        }
    
        .event-paragraph {
            text-align: center;
        }
    }
    
    .admin-nav-buttons {
        gap: 55%;
    }
}

@media (max-width: 380px) {
    .admin-buttons-container {
        gap: 10%;
    }
    .admin-nav-buttons {
        gap: 50%;
    }
}