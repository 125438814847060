.homepage-container{
    margin-top: 115px;
}

.ellipse-1, .ellipse-2 {
    position: absolute;
    height: 580px;
    z-index: -1;
}

.ellipse-1 {
    margin-left: 1200px;
    margin-top: -270px;
}

.ellipse-2 {
    margin-top: -165px;
    margin-left: -100px;
}

.home-description {
    width: 88vw;
}

.home-about {
    color: var(--light-green);
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    letter-spacing: 0.08em;
    position: relative;
    top: 75px;
    left: 80px;
}

.home-title {
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    letter-spacing: 0.08em;
    color: black;
    position: relative;
    margin-top: 80px;
    left: 80px;
}

.home-pictures-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    left: 80px;
    margin-top: 30px;
}

.home-golf-img {
    width: 33%;
    object-fit: cover;
    object-position: 50% 40%;
}

.home-description p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.08em;
    color: var(--light-gray);
    position: relative;
    top: 50px;
    left: 80px;
}

.home-paragraph{
    margin-bottom: 40px;
    text-align: justify;
}

.black-bg-2 {
    margin-top: 118px;
    width: 100vw;
    height: 1000px;
    position: absolute;
    z-index: -1;
}

.objectives-purposes-container {
    display: flex;
}

.obj-prps-container {
    display: flex;
    justify-content: space-evenly;
    width: 100vw;
    z-index: 5;
    left: 0;
}

.object-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mission-icon {
    height: 140px;
    width: 140px;
    z-index: 2;
    margin-top: 200px;
    position: absolute;
}

.objectives-and-purposes {
    margin-top: 280px;
    margin-bottom: 100px;
    background-color: white;
    width: 70%;
    text-align: center;
    padding: 60px 50px 60px 50px;
    font-family: "Nunito Sans", sans-serif;
    border-radius: 10px;
    color: var(--light-gray);
}

.obj-title {
    font-size: 30px;
    font-weight: 500;
    margin-top: 45px;
    margin-bottom: 30px;
    letter-spacing: 2px;
}

.obj-purposes {
    display: flex;
    text-align: left;
    justify-content: center;
    gap: 5%;
}

.obj-sentence {
    font-size: 17px;
    letter-spacing: 1px;
    font-weight: 400;
    margin-bottom: 20px;
}

.obj-prps-list {
    font-size: 16px;
    letter-spacing: 1px;
    width: 50%;
    margin-left: 20px;
}

.obj-prps-list li {
    margin-bottom: 10px;
}

@media (max-width: 1300px) {
    .homepage-container{
        margin-top: 90px;
    }
    
    .ellipse-1, .ellipse-2 {
        position: absolute;
        height: 380px;
    }
    
    .ellipse-1 {
        margin-left: 80%;
        margin-top: -20%;
    }
    
    .ellipse-2 {
        margin-top: -13%;
        margin-left: -100px;
    }
    
    .home-description {
        width: 84%;
    }
    
    .home-paragraph{
        margin-bottom: 30px;
    }

    .home-about {
        font-size: 22px;
        top: 50px;
        left: 80px;
    }
    
    .home-title {
        font-size: 34px;
        margin-top: 55px;
        left: 80px;
    }

    .home-pictures-container {
        left: 80px;
        margin-top: 30px;
    }
    
    .home-golf-img {
        width: 33%;
        object-fit: cover;
        object-position: 50% 50%;
    }
    
    .home-description p {
        font-size: 15px;
        top: 30px;
        left: 80px;
    }

    .black-bg-2 {
        margin-top: 90px;
        width: 100vw;
        height: 1500px;
    }
    
    .obj-prps-container {
        display: flex;
        flex-direction: column;
        width: 100vw;
    }
    
    .object-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .mission-icon {
        height: 120px;
        width: 120px;
        margin-top: 180px;
    }
    
    .objectives-and-purposes {
        margin-top: 245px;
        width: 80%;
        padding: 60px 50px 60px 50px;
    }
    
    .obj-title {
        margin-top: 27px;
        margin-bottom: 27px;
        font-size: 28px;
    }

    .obj-sentence {
        font-size: 15px;
    }
    
    .obj-prps-list {
        font-size: 15px;
    }
}

@media (max-width: 900px) {
    .black-bg-2 {
        margin-top: 90px;
        width: 100vw;
        height: 1700px;
    }

    .home-about {
        left: 60px;
    }
    
    .home-title {
        left: 60px;
    }

    .home-pictures-container {
        left: 60px;
    }
    
    .home-description p {
        left: 60px;
    }
}

@media (max-width: 600px) {
    .homepage-container{
        margin-top: 60px;
    }

    .ellipse-1, .ellipse-2 {
        display: none;
    }
    
    .home-description {
        width:71%;
    }

    .home-pictures-container {
        flex-direction: column;
        align-items: center;
        gap: 10px;
        left: 60px;
    }

    .home-golf-img {
        width: 120%;
        height: 150px;
        object-fit: cover;
        object-position: 50% 30%;
    }
    
    .home-paragraph{
        margin-bottom: 20px;
    }

    .home-about {
        font-size: 20px;
        top: 50px;
        text-align: center;
    }
    
    .home-title {
        font-size: 28px;
        margin-top: 55px;
        text-align: center;
    }
    
    .home-description p {
        font-size: 14px;
        top: 30px;
    }
    
    .mission-icon, .vision-icon {
        height: 110px;
        width: 110px;
    }
    
    .mission, .vision {
        width: 350px;
        padding: 50px 40px 50px 40px;
    }
    
    .obj-title {
        margin-top: 22px;
        margin-bottom: 22px;
        font-size: 24px;
    }

    .obj-purposes {
        flex-direction: column;
    }

    .obj-sentence {
        font-size: 14px;
    }
    
    .obj-prps-list {
        font-size: 14px;
        width: 92%;
    }

    .black-bg-2 {
        height: 2000px;
    }
}


@media (max-width: 400px) {
    .black-bg-2 {
        height: 2400px;
    }
    
    .objectives-and-purposes {
        width: 330px;
    }

    .home-description {
        margin-left: -5px;
    }
}

@media (max-width: 280px) {
    .black-bg-2 {
        height: 2400px;
    }

    .home-about {
        display: flex;
        width: 100vw;
        font-size: 17px;
        justify-content: center;
        left: 0;
    }
    
    .home-title {
        font-size: 22px;
        display: flex;
        width: 100vw;
        justify-content: center;
        left: 0;
    }

    .home-description p {
        font-size: 11px;
        left: 45px;
    }
    
    .objectives-and-purposes {
        width: 260px;
        padding: 50px 40px 50px 40px;
    }
    
    .obj-title {
        margin-top: 22px;
        margin-bottom: 22px;
        font-size: 20px;
    }

    .obj-sentence {
        font-size: 11px;
    }
    
    .obj-prps-list {
        font-size: 11px;
    }
}
