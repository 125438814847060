.footer-container {
  background-color: #005E30;
  color: white;
  padding: 35px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.footer-section {
  text-align: left;
}

.footer-title {
  font-size: 10px;
  margin-bottom: 10px;
}

.footer-info {
  font-size: 12px;
}

.membership-info {
  margin-top: 15px;
}

.membership-info {
  font-size: 15px;
}

.footer-section .footer-info .membership-info {
  margin-bottom: 10px;
}

.footer-section .footer-info div:not(.footer-title) {
  margin-bottom: 10px;
}

.menu-item {
font-weight: normal;
}

.social-media-info {
display: flex;
align-items: flex-start;
flex-direction: column;
justify-content: space-between;
height: 30px;
}


.social-media-info img {
margin-right: 10px;
}

.footer-info img {
margin-right: 10px;
}

.footer-container {
font-family: Arial, sans-serif;
}

.footer-title {
font-weight: bold;
font-size: 13px;
}

.footer-info {
font-size: 10px;
}

.big-text {
font-size: 30px; 
}

.footer-section .col:last-child .row:last-child {
font-size: smaller; 
}

.footer-section .col:last-child .row:last-child {
white-space: nowrap;
}

.footer-section .row:last-child {
font-size: 20px;
font-family: 'Times New Roman', Times, serif;
}

.big-text .row:first-child {
font-size: 20px;
}

.small-text {
font-size: 1px;
}

.regular-font {
  font-size: 11px;
  font-weight: normal;
  font-family: Arial, sans-serif;
  color: white;
}

.footer-container .footer-section .regular-font {
  margin-top: -2px;
}

.phone-col {
  margin-bottom: 20px;
  margin-left: 30px;
  margin-top: -60px;
}

.phone-number-footer {
font-size: 15px;
font-weight: 400;
letter-spacing: 1px;
}

.phone-description {
font-size: 10px;
font-weight: normal;
}

.altavista-col {
  display: flex;
  margin-bottom: 10px;
  padding-top: 22px;
}

.altavista-title-footer-container {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  justify-content: center;
}

.altavista-title {
  font-family: "Times New Roman", Times, serif;
  font-size: 27px;
}


.altavista-description {
font-size: 18px;
font-weight: normal;
}

.location-col {
margin-bottom: 10px;
margin-left: 30px;
margin-top: -30px;
}

.location-description {
font-size: 10px;
font-weight: normal;
}

.location-name {
  font-size: 14px;
  margin-bottom: -3px;
  font-weight: 400;
  letter-spacing: 1px;
}

.footer-container a {
  color: white;
  text-decoration: none;
}
  
.sport-container {
margin-left: 5rem;
}

.sport-text {
font-size: 25px;
font-weight: normal;
color: white;
}

.footer-container {
padding: 20px;
}

.altavista-col {
  margin-left: 0px !important;
  padding-left: 0px !important;
  position: relative !important;
  left: -0px !important;
  top: 10px !important;
}

@media only screen and (max-width: 1025px) {
  .footer-container {
    font-size: 10px !important;
    margin-left: 0px !important;
    margin-right: -5px !important;
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .location-name {
    margin-bottom: 0px;
  }

  .landing-container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;    
  }

  .footer-section {
    font-size: 7px !important;
    width: calc(100% / 1) !important;
    box-sizing: border-box !important;
    padding: 10px !important;
  }

  .phone-col {
    margin-left: 30px;
  }

  .location-col{
    margin-left: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .phone-col {
    margin-left: 30px;
  }

  .location-col{
    margin-left: 30px;
  }
}

@media (max-width: 300px) {
  .altavista-title{
    font-size: 18px;
  }

  .altavista-description{
    font-size: 13px;
  }

}


