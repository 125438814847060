.navbar-top {
  background: var(--white);
  height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 100;
}

.navbar-top-left {
  display: flex;
  align-items: center;
}

.title-container {
  display: flex;
  flex-direction: column;
}

.navbar-top-right {
  display: flex;
  margin-right: 17px;
  align-items: center;
}

.phone-container, .location-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.logo {
  height: 60px;
  width: 61px;
  margin: 9px 3px 10px 16px;
}

.navbar-dropdown-button, .navbar-dropdown-content {
  display: none;
}

.title-1 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 27px;
  margin-bottom: -4px;
  margin-top: 0px;
  font-weight: 500;
}

.title-2 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 15px;
  font-weight: 500;
}

.phone-icon-1, .location-icon-1 {
  margin: 11px;
  height: 25px;
  width: 25px;
}

.address-1, .phone-number {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 1px;
  margin-top: 0px;
  letter-spacing: 0.2px;
}

.phone-number {
  margin-right: 30px;
}

.address-2, .trunkline {
  font-size: 10px;
}

.phone-link, .location-link {
  text-decoration: none;
  color: black;
}

.navbar-bottom {
  background: var(--black);
  height: 41px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 74px;
  position: fixed;
  z-index: 100;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.304);
}

.nav-list {
  color: white;
  text-decoration: none;
  font-size: 12px;
  padding: 21px;
  letter-spacing: 1px;
}

.home {
  margin-left: 16px;
}

.nav-list:hover {
  color: var(--light-green);
}

.login-button {
  background-color: var(--light-green);
  color: white;
  font-size: 12px;
  border: none;
  height: 41px;
  width: 100px;
  margin-right: 56px; 
}

.login-button:hover {
  font-size: 13px;
  background-color: #009d51;
  cursor: pointer;
}


@media (max-width: 1025px) {
  .navbar-top {
    padding: 0;
    height: 60px;
  }

  .navbar-bottom {
    height: 30px;
    top: 60px;
  }

  .logo{
    height: 50px;
    width: 51px;
  }

  .title-1{
    font-size: 22px;
  }

  .title-2 {
    font-size: 13px;
  }

  .phone-icon-1, .location-icon-1 {
    margin: 9px;
    height: 21px;
    width: 21px;
  }
  
  .address-1, .phone-number {
    font-size: 11px;
  }

  .phone-number {
    margin-right: 15px;
  }
  
  .address-2, .trunkline {
    font-size: 8px;
  }

  .navbar-top-right {
    margin-right: 30px;
  }

  .nav-list {
    padding: 10px;
    font-size: 10px;
  }

  .login-button {
    margin-right: 30px;
    height: 30px;
    width: 80px;
    font-size: 10px;
  }
}

@media (max-width: 600px) {
  .navbar-top {
    padding: 0;
    height: 60px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .navbar-bottom {
    display: none;
  }

  .logo{
    height: 50px;
    width: 51px;
  }

  .title-1{
    font-size: 22px;
  }

  .title-2 {
    font-size: 13px;
  }

  .phone-container, .location-container, .phone-icon-1, .location-icon-1 {
    display: none;
  }

  .navbar-icon {
    height: 45px;
  }

  .navbar-dropdown-button {
    display: flex;
    background-color: white;
    border: none;
    margin-right: -20px;
  }

  .navbar-dropdown-content {
    position: absolute;
    top: 80%;
    right: 2%;
    border: 2px solid #a2a2a2;
    list-style-type: none;
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
  }
  
  .navbar-dropdown-content > li > a {
    text-decoration: none;
    color: black;
    font-family: "Roboto", sans-serif;
    font-size: 11px;
    font-weight: 450;
    letter-spacing: 1px;
    padding: 10px 10px;
  }

  .navbar-dropdown-content > li {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .navbar-dropdown-content > li > a:hover {
    color: var(--light-green);
  } 
} 