.slideshow-container {
    overflow: hidden;
    position: relative;
    z-index: 10;
}

.slideshow {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.slide {
    width: 100%; 
    height: 100%;
}

.slideshow-container::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1;
}

.hero-content {
    position: absolute;
    width: 100vw;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.hero-title {
    font-weight: 500;
    font-size: 64px;
    color: white;
    letter-spacing: 2px;
}

.hero-slogan {
    font-weight: lighter;
    font-size: 48px;
    color: white;
    letter-spacing: 1px;
}

.hero-button {
    font-family: "Nunito Sans", sans-serif;
    font-weight: bold;
    font-size: 13px;
    background-color: var(--light-green);
    color: white;
    width: 162px;
    height: 48px;
    border: none;
    letter-spacing: 1px;
    margin-top: 20px;
}

.hero-button:hover{
    font-size: 13.4px;
    background-color: #009d51;
    cursor: pointer;
}

@media (min-width: 1920px) {
    .slideshow-container {
        height: 90vh;
    }

    .slideshow {
        height: 100%;
    }
}

@media (max-width: 1300px) {
    .hero-content {
        top: 23%;
    }
    
    .hero-title {
        font-size: 38px;
    }
    
    .hero-slogan {
        font-size: 26px;
    }
    
    .hero-button {
        font-size: 10px;
        width: 130px;
        height: 38px;
    }

    .hero-button:hover {
        font-size: 10px;
        background-color: var(--light-green);
    }
}

@media (max-width: 900px) {
    .hero-content {
        top: 23%;
    }
    
    .hero-title {
        font-size: 38px;
    }
    
    .hero-slogan {
        font-size: 26px;
    }
    
    .hero-button {
        font-size: 10px;
        width: 130px;
        height: 38px;
    }

    .hero-button:hover {
        font-size: 10px;
        background-color: var(--light-green);
    }
}

@media (max-width: 600px) {
    .slideshow-container {
        height: 397px;
    }

    @keyframes slide {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(-180%);
        }
    }
      
    .slideshow {
        display: flex;
        width: 500%;
        animation: slide 120s linear infinite;
        animation-fill-mode: forwards;
    }
    
    .slide {
        width: 864px; 
        height: 397px;
    }

    .hero-content {
        height:400px;
        top: 38%;
        left: 50%;
    }
    
    .hero-title {
        font-size: 25px;
        letter-spacing: 1px;
    }
    
    .hero-slogan {
        font-size: 21px;
        letter-spacing: 0px;
    }
    
    .hero-button {
        font-size: 9px;
        width: 115px;
        height: 35px;
    }

    .hero-button:hover {
        font-size: 9px;
    }
}

@media (max-width: 600px) and (max-height: 800px) {
    .hero-content {
        top: 50%;
    }

    .hero-title {
        font-size: 22px;
        letter-spacing: 0px;
    }
}

@media (min-width: 380px) and (max-width: 440px) {
    .hero-content {
        top: 45%;
    }
}

@media (max-width: 380px) {
    .hero-content {
        top: 55%;
    }

    .hero-title {
        font-size: 18px;
    }

    .hero-slogan {
        font-size: 16px;
    }

    .hero-button {
        font-size: 8px;
        width: 100px;
        height: 30px;
    }
}

@media (max-width: 350px) {
    .hero-content {
        top: 45%;
    }
}
