.edit-modal {
    width: 45vw !important;
}

.ant-modal-title {
    font-weight: 400 !important;
    font-size: 23px !important;
    padding: 12px 0 18px 0;
    letter-spacing: 1px;
}

.modal-text-btn {
    font-size: 15px !important;
}

.modal-box-edit {
    width: 95% !important;
}

.event-container {
    display: flex;
    margin-left: 120px;
}

.event-photo-1 {
    width: 35%;
    min-height: 35%;
    object-fit: cover;
    object-position: 50% 50%;
}

.announcement-event {
    .event-photo-1 {
        min-height: 380px;
        max-height: 380px;
        object-fit: cover;
        object-position: 50% 50%;
        border: 1px solid rgba(0, 0, 0, 0.114);
    }
}

.event-about {
    display: flex;
    flex-direction: column;
    margin-left: 55px;
}

.event-main-title {
    font-weight: 600;
    font-size: 32px;
    color: var(--dark-green);
    letter-spacing: 2px;
    margin-top: 18px;
}

.event-second-title {
    font-weight: 500;
    font-size: 23px;
    letter-spacing: 1px;
}

.event-date-container {
    display: flex;
    align-items: center;
    height: 15px;
    margin-top: 6px;
}

.clock-icon {
    height: 10px;
    margin-bottom: -11px;
}

.event-date {
    font-size: 14px;
    margin-top: 12px;
    margin-left: 5px;
    color: var(--light-gray);
    font-weight: 300;
    letter-spacing: 1px ;
}

.event-paragraph {
    font-size: 18px;
    width: 710px;
    color: var(--light-gray);
    line-height: 20px;
    margin-top: 42px;
    letter-spacing: 1px;
}

.line {
    width: 90%;
    height: 1px;
    background-color: #717171;
    margin: 70px auto;
}

.read-button {
    margin-top: 40px;
    height: 46px;
    width: 180px;
    font-size: 17px;
    font-weight: 500;
    color: white;
    background-color: var(--light-green);
    letter-spacing: 1px;
    border: none;
}

.read-button:hover {
    font-size: 17.3px;
    background-color: #009d51;
    cursor: pointer;
}

@media (max-width: 1300px) {
    .edit-modal {
        width: 61vw !important;
    }

    .event-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 0;
    }
    
    .event-photo {
        width: 50%;
        height: 50%;
    }
    
    .event-about {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 0;
    }
    
    .event-paragraph {
        font-size: 17px;
        width: 70%;
        margin-top: 35px;
        text-align: center;
    }
    
    .line {
        width: 80%;
        margin: 50px auto;
    }
    
    .read-button {
        margin-top: 20px;
        height: 44px;
        width: 160px;
        font-size: 15px;
        font-weight: 500;
    }
    
    .read-button:hover {
        font-size: 15.3px;
        background-color: var(--light-green);
    }
}

@media (max-width: 900px) {
    .edit-modal {
        width: 70vw !important;
    }

    .announcement-event {
        .event-photo-1 {
            min-height: 300px;
            max-height: 300px;
        }
    }
}

@media (max-width: 600px) {
    .edit-modal {
        width: 90vw !important;
    }
    
    .event-photo {
        width: 65%;
        height: 65%;
    }

    .event-main-title {
        font-size: 25px;
        margin-top: 30px;
        text-align: center;
    }
    
    .event-second-title {
        font-size: 17px;
        margin-top: -6px;
    }
    
    .event-paragraph {
        font-size: 15px;
        width: 80%;
        margin-top: 30px;
    }
    
    .read-button {
        margin-top: 15px;
        height: 40px;
        width: 140px;
        font-size: 13px;
    }
    
    .read-button:hover {
        font-size: 13.3px;
    }

    .announcement-event {
        .event-photo-1 {
            min-height: 220px;
            max-height: 220px;
        }
    }
}
