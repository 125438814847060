.story-page-container {
    padding: 170px 100px 60px 100px;
    background-color: #F5F5F5;
}

.ellipse-4 {
    position: absolute;
    height: 580px;
    margin-left: -220px;
    margin-top: -100px;
    z-index: 0;
}

.story-event {
    .event-container {
        flex-direction: row-reverse;
        margin: 0;
    }

    .event-about-container {
        margin: 0;
        padding: 0;
    }

    .about-titles, .about-paragraph {
        width: 90%;
        margin-left: 0;
        text-align: justify;
    }

    .event-paragraph {
        width: 100%;
        white-space: pre-wrap;
    }

    .full-story-carousel {
        background-color: black;
        height: 400px;
        min-width: 55%;
        max-width: 55%;
    }
    
    .carousel-image {
        min-width: 100%;
        height: 400px;
        object-fit: cover;
        object-position: 50% 50%;
    }

    .event-main-title {
        font-size: 50px;
        color: var(--dark-green);
        font-weight: 500;
        letter-spacing: 1px;
        z-index: 5;
    }

    .event-second-title {
        font-size: 30px;
        letter-spacing: 1px;
        z-index: 5;
    }

    .event-date {
        font-size: 20px;
        margin-top: 20px;
        color: var(--light-gray);
        letter-spacing: 1px;
        font-weight: 400;
        z-index: 5;
    }

    .clock-icon {
        height: 18px;
        margin-bottom: -20px;
        margin-right: 5px;
    }

    .about-titles {
        margin-top: 12%;
    }

    .event-date-container {
        margin-top: 4%;
    }

    .about-paragraph {
        margin-top: 25%;
        width: 222%;
    }

    .event-paragraph {
        font-size: 17px;
        color: var(--light-gray);
        letter-spacing: 1px;
        line-height: 30px;
        margin-bottom: 40px;
    }

    .read-button {
        display: none;
    }

    .line {
        display: none;
    }
}

.fs-button-div {
    display: flex;
    justify-content: flex-end;
}

.full-story-back-btn {
    border: none;
    border-radius: 2px;
    background-color: var(--light-green);
    color: white;
    height: 35px;
    width: 10%;
    font-size: 14px;
}

.full-story-back-btn:hover {
    background-color: #01904b;
    cursor: pointer;
}

@media (max-width: 1025px) {
    .story-page-container {
        padding: 130px 80px 80px 80px;
    }

    .ellipse-4 {
        display: none;
    }

    .story-event {
        .event-container {
            align-items: flex-start;
        }
    
        .about-titles, .about-paragraph {
            width: 90%;
            margin-left: 0;
        }

        .event-about {
            align-items: flex-start;
        }
    
        .event-paragraph {
            width: 100%;
        }
    
        .full-story-carousel {
            height: 300px;
            min-width: 60%;
        }
        
        .carousel-image {
            height: 300px;
        }
    
        .event-main-title {
            font-size: 40px;
        }
    
        .event-second-title {
            font-size: 25px;
        }
    
        .event-date {
            font-size: 16px;
        }
    
        .clock-icon {
            height: 14px;
        }
    
        .about-titles {
            margin-top: 20%;
        }
    
        .event-date-container {
            margin-top: 4%;
        }
    
        .about-paragraph {
            margin-top: 20%;
            width: 250%;
        }
    
        .event-paragraph {
            font-size: 16px;
            line-height: 25px;
            text-align: left;
        }
    }

    .fs-button-div {
        justify-content: center;
    }

    .full-story-back-btn {
        height: 35px;
        width: 15%;
        font-size: 14px;
    }
}

@media (max-width: 900px) {
    .story-page-container {
        padding: 130px 50px 50px 50px;
    }

    .story-event {
        .event-container {
            align-items: flex-start;
        }
    
        .about-titles, .about-paragraph {
            width: 95%;
        }
    
        .full-story-carousel {
            height: 240px;
            min-width: 60%;
        }
        
        .carousel-image {
            height: 240px;
        }
    
        .event-main-title {
            font-size: 32px;
        }
    
        .event-second-title {
            font-size: 20px;
        }
    
        .event-date {
            font-size: 14px;
        }
    
        .clock-icon {
            height: 12px;
        }
    
        .about-titles {
            margin-top: 10%;
        }
    
        .event-date-container {
            margin-top: 2%;
        }
    
        .about-paragraph {
            margin-top: 20%;
            width: 250%;
        }
    
        .event-paragraph {
            font-size: 14px;
            line-height: 25px;
        }
    }
}



@media (max-width: 600px) {
    .story-page-container {
        padding: 100px 30px 40px 30px;
    }

    .story-event {
        .about-titles, .about-paragraph {
            width: 90%;
            margin-left: 0;
        }

        .event-about {
            align-items: center;
        }
    
        .full-story-carousel {
            height: 250px;
            min-width: 100%;
            margin-left: -89vw;
        }
        
        .carousel-image {
            height: 250px;
        }
    
        .event-main-title {
            font-size: 37px;
            margin-top: 50%;
        }
    
        .event-second-title {
            font-size: 22px;
        }
    
        .event-date {
            font-size: 16px;
        }
    
        .clock-icon {
            height: 14px;
        }
    
        .about-titles {
            width: 88vw;
        }
    
        .event-date-container {
            margin-top: 1%;
        }
    
        .about-paragraph {
            margin-top: 4%;
            width: 88vw;
        }
    
        .event-paragraph {
            font-size: 16px;
            line-height: 25px;
        }

        .event-second-title{
            text-align: center;
        }
    }
    .full-story-back-btn {
        width: 20%;
    }
}

@media (max-width: 500px) {
    .story-event {    
        .full-story-carousel {
            height: 200px;
            min-width: 100%;
            margin-left: -89vw;
        }   
        
        .carousel-image {
            height: 200px;
        }
    }
}

@media (max-width: 400px) {
    .story-event {     
        .full-story-carousel {
            margin-left: -84vw;
        } 

        .event-main-title {
            font-size: 30px;
            margin-top: 70%;
        }
    
        .event-second-title {
            font-size: 18px;
        }
    
        .event-date {
            font-size: 13px;
        }
    
        .clock-icon {
            height: 11px;
        }
    
        .event-date-container {
            margin-top: 1%;
        }
    
        .event-paragraph {
            font-size: 13px;
        }

        .about-paragraph {
            margin-top: 2%;
            width: 84vw;
        }

        .about-titles{
            width: 84vw;
        }
    }
    .full-story-back-btn {
        width: 30%;
    }
}

@media (max-width: 300px) {
    .story-event {    
        .full-story-carousel {
            height: 150px;
            margin-left: -80vw;
        }   
        
        .carousel-image {
            height: 150px;
        }
    
        .event-main-title {
            font-size: 20px;
            margin-top: 65%;
        }
    
        .event-second-title {
            font-size: 15px;
        }
    
        .event-date {
            font-size: 11px;
        }
    
        .clock-icon {
            height: 9px;
        }
    
        .event-date-container {
            margin-top: 1%;
        }
    
        .event-paragraph {
            font-size: 11px;
            line-height: 20px;
        }

        .about-paragraph {
            margin-top: 1%;
            width: 80vw;
        }

        .about-titles{
            width: 80vw;
        }
    }
    .full-story-back-btn {
        width: 40%;
    }
}