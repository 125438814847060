.form-container {
    background-color: white;
    height: 500px;
    width: 35vw;
    padding-top: 10px;
}

.title-logo-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: 35px;
    margin-right: 35px;
}

.login-title-1 {
    font-family: 'Times New Roman';
    font-size: 28px;
    font-weight: 500;
}

.login-title-2 {
    font-size: 18px;
    margin-top: 25px;
}

.input-entries {
    margin-left: 35px;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.input-label {
    font-size: 13px;
}

.input-box {
    width: 90%;
    height: 29px;
    margin-bottom: 13px;
    padding-left: 10px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.575);
}

.input-box:hover, .input-box:focus, .input-box:focus-within {
    border-color: rgba(0, 0, 0, 0.88);
    box-shadow: 0px 0px 2px #888888;
}

.alta-logo {
    width: 80px;
    height: 79px;
}

.account-question-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
}

.account-question-container > p {
    font-size: 13.5px;
    margin-bottom: 3px;
}

.form-button {
    color: var(--light-green);
}

.register-entry {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.register-entry-1, .register-entry-2 {
    display: flex;
    flex-direction: column;
    width: 49%;
}

.input-box-half {
    width: 100%;
    height: 29px;
    margin-bottom: 13px;
    padding-left: 10px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.575);
}

.register-container {
    margin-top: 10px;
}


@media (max-width: 1300px) {
    .form-container {
        height: 500px;
        width: 45vw;
        padding-left: 10px;
    }
    
    .input-box-half {
        width: 90%;
        height: 30px;
        margin-bottom: 13px;
    }
    
    .sixty-three {
        display: none;
    }

    .login-title-1 {
        font-size: 24px;
    }

    .login-title-2{
        font-size: 17px;
    }

    .input-box {
        width: 85.5%;
        height: 30px;
    }
}

@media (max-width: 900px) {
    .form-container {
        height: 400px;
        width: 40vw;
        padding-left: 0px;
    }

    .title-logo-container {
        margin-top: 10px;
        margin-left: 20px;
        margin-right: 10px;
    }

    .alta-logo {
        width: 60px;
        height: 59px;
        margin-top: -8px;
    }
    
    .login-title-1 {
        font-size: 20px;
    }
    
    .login-title-2 {
        font-size: 14px;
        margin-top: 13px;
    }

    .input-entries {
        margin-left: 20px;
        margin-top: 16px;
    }
    
    .input-label {
        font-size: 11px;
    }
    
    .input-box {
        width: 90%;
        height: 20px;
        margin-bottom: 6px;
        padding-left: 5px;
    }

    .input-box-half {
        width: 90%;
        height: 20px;
        margin-bottom: 6px;
        padding-left: 5px;
    }
    
    .register-entry {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .register-entry-1, .register-entry-2 {
        width: 100%;
        flex-direction: column;
    }
    
    .account-question-container {
        margin-top: 6px;
    }
    
    .account-question-container > p {
        font-size: 11px;
        margin-bottom: 0px;
    }
}

@media (max-width: 600px) {
    .form-container {
        height: 400px;
        width: 80vw;
    }
}

