.background-image {
    position: absolute;
    height: 800px;
    width: 100vw;
    object-fit: cover;
    object-position: 50% 50%;
    z-index: -2;
}

.ad-form-container {
    display: flex;
    margin-top: 115px;
    justify-content: center;
    padding-top: 120px;
    padding-bottom: 130px;
}

.ad-container {
    background: hsla(0, 0%, 0%, 0.7);
    backdrop-filter: blur(5px);
    width: 35vw;
    height: 500px;
    color: white;
    font-size: 18px;
    padding-top: 115px;
    padding-left: 55px;
}

.ad-title {
    font-size: 22px;
    margin-bottom: 40px;
}

.ad-phrase {
    margin-bottom: 22px;
    font-weight: 300;
}

.account-uses > li {
    list-style-type: none;
    margin-bottom: 10px;
    font-weight: 300;
}

.check-icon {
    margin-right: 10px;
}


@media (max-width: 1300px) {
    .background-image {
        height: 800px;
        width: 100vw;
    }
    
    .ad-form-container {
        margin-top: 90px;
    }
    
    .ad-container {
        width: 45vw;
        height: 500px;
        font-size: 15px;
        padding-top: 130px;
        padding-left: 50px;
    }
    
    .ad-title {
        font-size: 19px;
    }
}

@media (max-width: 900px) {
    .background-image {
        height: 700px;
    }

    .ad-form-container {
        margin-top: 70px;
    }

    .ad-container {
        width: 40vw;
        height: 400px;
        font-size: 13px;
        padding-top: 70px;
        padding-left: 28px;
    }
    
    .ad-title {
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .background-image {
        top: 0;
        height: 1200px;
    }

    .ad-form-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
    }

    .ad-container {
        width: 80vw;
        height: 330px;
    }
    
    .ad-title {
        font-size: 15px;
    }
}

@media (max-width: 380px) {
    .ad-container {
        width: 80vw;
        height: 300px;
        font-size: 11px;
        padding-top: 50px;
        padding-left: 28px;
    }
    
    .ad-title {
        font-size: 13px;
        margin-bottom: 25px;
    }

    .ad-phrase {
        margin-bottom: 20px;
    }

    .account-uses > li {
        margin-bottom: 5px;
    }
}