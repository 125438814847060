@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

:root {
  --dark-green: #005E30;
  --light-green: #02A556;
  --light-brown: #AFA464;
  --light-orange: #EFB758;
  --light-gray: #484848;
  --black: #121110;
  --white: #FFFFFF;
}

body {
  overflow-x: hidden; 
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: var(--black);
}

::-webkit-scrollbar-thumb {
  background-color: #7c7c7c;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #656565;
}

@media (max-width: 600px) {
  body {
    overflow-x: hidden; 
  }

  /* Hide vertical scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }
}