.adlogin-background-image {
    position: absolute;
    height: 800px;
    width: 100vw;
    object-fit: cover;
    object-position: 50% 50%;
    z-index: -2;
}

.adlogin-form-container {
    display: flex;
    margin-top: 115px;
    justify-content: center;
    padding-top: 110px;
    padding-bottom: 130px;
}

.adlogin-container {
    display: flex;
    flex-direction: column;
    background: white;
    backdrop-filter: blur(5px);
    width: 35vw;
    height: 500px;
    font-size: 18px;
    padding: 80px 55px 0 55px;
}

.adlogin-title {
    font-size: 30px;
    margin-bottom: 40px;
    letter-spacing: 2px;
    font-weight: 400;
    color: var(--light-gray);
}

.adlogin-input-label {
    font-size: 15px;
    margin-bottom: 0px;
    letter-spacing: 1px;
    font-weight: 400;
    color: black;
}

.adlogin-input-box {
    padding-left: 10px;
    margin-bottom: 20px;
    font-weight: 300;
    border: 1px solid rgba(0, 0, 0, 0.427);
    height: 40px;
    border-radius: 2px;
}

.adlogin-input-box:hover, .adlogin-input-box:focus, .adlogin-input-box:focus-within {
    border-color: rgba(0, 0, 0, 0.88);
    box-shadow: 0px 0px 2px #888888;
}

.adlogin-enter-button {
    display: flex;
    justify-content: center;
}

.adlogin-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-color: var(--light-green);
    width: 100%;
    height: 40px;
    font-size: 15px;
    letter-spacing: 2px;
    border-radius: 5px;
    color: white;
    margin-top: 30px;
    border: none;
}

.adlogin-button:hover {
    background-color: #009e52;
    cursor: pointer;
}

@media (max-width: 1300px) {
    .adlogin-background-image {
        height: 800px;
        width: 100vw;
    }
    
    .adlogin-form-container {
        margin-top: 90px;
    }
    
    .adlogin-container {
        width: 50vw;
        height: 500px;
        font-size: 15px;
        padding: 80px 45px 0 45px;
    }
    
    .adlogin-title {
        font-size: 25px;
    }
}

@media (max-width: 900px) {
    .adlogin-background-image {
        height: 700px;
    }

    .adlogin-form-container {
        margin-top: 70px;
    }

    .adlogin-container {
        font-size: 13px;
        padding: 50px 30px 0 30px;
        height: 400px;
    }
    
    .adlogin-title {
        font-size: 23px;
    }

    .adlogin-input-label {
        font-size: 13px;
    }
    
    .adlogin-input-box {
        margin-bottom: 20px;
        height: 35px;
    }    

    .adlogin-button {
        height: 35px;
    }
}

@media (max-width: 600px) {
    .adlogin-background-image {
        height: 600px;
    }

    .adlogin-form-container {
        margin-top: 60px;
    }

    .adlogin-container {
        height: 330px;        
        width: 80vw;
        padding: 40px 30px 0 30px;
    }
    
    .adlogin-title {
        font-size: 20px;
    }

    .adlogin-input-label {
        font-size: 11px;
    }
    
    .adlogin-input-box {
        margin-bottom: 15px;
        height: 30px;
    }    

    .adlogin-button {
        height: 30px;
    }
}

@media (max-width: 380px) {
    .adlogin-container {
        padding: 30px 20px 0 20px;
    }
}