.modal-input-label, .asterisk {
    font-size: 14px;
    letter-spacing: 1px;
    margin-bottom: 0px;
}

.asterisk {
    color: red;
}

.modal-input-box {
    width: 100%;
    height: 35px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.575);
    margin-top: -2px;
    padding-left: 10px;
}


.modal-subtitle-date-container {
    display: flex;
    justify-content: space-between;
}

.modal-date-container, .modal-subtitle-container {
    width: 90%;
}

.modal-description-box {
    height: 100px;
    padding: 10px 10px;
}

.admin-add-button {
    background-color: var(--light-green);
}

.admin-add-button:hover {
    background-color: #02974f;
    cursor: pointer;
}

.date-picker {
    width: 100%;
    height: 35px;
    border: 1px solid rgba(0, 0, 0, 0.575);
}

.modal-input-box:hover, .modal-input-box:focus, .modal-input-box:focus-within, .date-picker:hover, .date-picker:focus, .date-picker:focus-within {
    border-color: rgba(0, 0, 0, 0.88);
    box-shadow: 0px 0px 2px #888888;
}

.ant-picker-now-btn {
    color: var(--light-green);
    text-decoration: none;
}

.image-uploader-container {
    padding: 10px 10px;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px dashed var(--light-gray);
}

.error-message {
    position: absolute;
    margin-top: -16px;
    margin-left: 10px;
    font-size: 9px;
    font-weight: 500;
    letter-spacing: 1px;
    width: auto;
    color: white;
    background-color: rgb(189, 24, 24);
    padding: 1px 3px;
    border-radius: 2px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.547);
}

.error-message.date-err {
    margin-top: -2px !important;
}

.error-message::before {
    content: '';
    position: absolute;
    top: -10px; /* Adjust this value to position the arrow */
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgb(189, 24, 24) transparent;
}

@media (max-width: 600px) {
    .modal-input-label, .asterisk{
        font-size: 11px;
    }
}