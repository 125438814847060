.facilities-container {
  background-color: var(--black);
}

.title-logo-wrap {
  padding: 185px 100px 70px 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.facilities-and-amenities {
  font-size: 45px;
  letter-spacing: 3px;
  margin-bottom: 30px;
  padding-top: 20px;
  color: white;
  font-weight: 500;
}

.facilities-upper-part {
  display: flex;
  padding: 0px 100px 70px 100px;
  background-color: var(--black);
}

.facilities-lower-part {
  display: flex;
  padding: 90px 100px 90px 100px;
  background-color: #F5F5F5;
}

.facilities-upper-part-1 {
  margin-top: -120px;
}

.facilities-upper-part-1, .facilities-lower-part-1 {
  width: 57%;
  display: flex;
  flex-direction: column;
}

.facilities-upper-part-2, .facilities-lower-part-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 45%;
}

.facilities-titles {
  width: 90%;
  color: white;
}

.golf-titles {
  width: 90%;
  color: var(--light-gray);
}

.rates-tables, .golf-rates-tables {
  display: flex;
  flex-direction: column;
  width: 90%;
  color: white;
}

.golf-rates-tables {
  color: var(--light-gray);
}

.rates-pictures, .golf-rates-pictures {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.f1-table-container, .f2-table-container {
  margin-top: 10px;
  margin-bottom: 30px;
}

.sports-photo, .golf-rates-photo {
  margin-bottom: 30px;
}

.sports-logo-1 {
  display: none;
}

.sports-logo-2 {
  margin-top: -50px;
  margin-right: 10px;
  max-width: 40%;
}

.tennis-photo {
  margin-top:-5px;
}

.sports-facilities, .rates-title, .golf-facilities ,.golf-rates-title {
  font-size: 30px;
  letter-spacing: 3px;
  color: var(--light-green);
  font-weight: 500;
}

.facilities-subtitle, .golf-subtitle {
  font-size: 22px;
  letter-spacing: 2px;
  margin-bottom: 29px;
  font-weight: 500;
}

.f-subtitle-1 {
  margin-bottom: 5px;
}

.f-subtitle-2 {
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 400;
}

.facilities-about, .golf-about {
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  letter-spacing: 1px;
  margin-bottom: 30px;
}

.golf-about {
  color: black;
}

.f1-table-title, .f2-table-title {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 2px;
}

.f2-table-title {
  width: 570px;
}

.table-1{
  width: 500px;
  border-spacing: 0;
}

.table-2 {
  width: 580px;
  border-spacing: 0;
}

.table-1 thead, .table-2 thead {
  background-color: #005E30;
}

.table-1 tbody, .table-2 tbody {
  background-color: white;
  color: black;
}

.table-1 th, .table-2 th {
  padding: 7px 20px 7px 20px;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1px;
  text-align: left;
  border: 1px solid rgba(75, 75, 75, 0.136);
}

.table-1 tbody th, .table-2 tbody th  {
  color: var(--light-gray)
}

.table-2 thead {
  color: white;
}

.table-footer {
  font-style: italic;
  margin-top: 5px;
  font-size: 12px;
  width: 580px;
}

span {
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
}

@media (max-width: 1300px) {
  .title-logo-wrap {
    padding: 10px 70px 70px 70px;
    justify-content: center;
  }

  .sports-logo-1 {
    display: flex;
    margin: auto;
    padding-top: 130px;
    width: 20%;
  }

  .sports-logo-2 {
    display: none;  
  }
  
  .facilities-upper-part {
    padding: 0px 70px 70px 70px;
  }

  .facilities-upper-part-1 {
    margin-top: 0px;
  }
  
  .facilities-lower-part {
    padding: 90px 70px 90px 70px;
  }
  
  .facilities-and-amenities {
    font-size: 38px;
    margin-bottom: -20px;
  }

  .f1-table-container, .f2-table-container {
    margin-top: 8px;
    margin-bottom: 25px;
  }
  
  .sports-photo, .golf-rates-photo {
    margin-bottom: 50px;
  }
  
  .tennis-photo {
    margin-top: 15px;
  }
  
  .sports-facilities, .rates-title, .golf-facilities ,.golf-rates-title {
    font-size: 25px;
  }
  
  .facilities-subtitle, .golf-subtitle {
    font-size: 20px;
  }

  .f-subtitle-2 {
    font-size: 18px;
  }
  
  .facilities-about, .golf-about {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 45px;
  }
  
  .f1-table-title, .f2-table-title {
    font-size: 18px;
  }
  
  .f2-table-title {
    width: 440px;
  }
  
  .table-1{
    width: 400px;
  }
  
  .table-2, .table-footer {
    width: 440px;
  }
  
  .table-1 th, .table-2 th {
    padding: 7px 10px 7px 10px;
    font-size: 15px;
    letter-spacing: 1px;
  }
  
  span {
    font-size: 15px;
  }
}

@media (max-width: 900px) {
  .facilities-upper-part {
    padding: 0px 70px 50px 70px;
  }
  
  .facilities-lower-part {
    padding: 60px 70px 70px 70px;
  }
  
  .facilities-and-amenities {
    font-size: 38px;
    text-align: center;
  }

  .f1-table-container, .f2-table-container {
    margin-top: 7px;
    margin-bottom: 22px;
  }
  
  .sports-photo, .golf-rates-photo {
    margin-bottom: 35px;
  }
  
  .tennis-photo {
    margin-top: 10px;
  }
  
  .sports-facilities, .rates-title, .golf-facilities ,.golf-rates-title {
    font-size: 22px;
  }
  
  .facilities-subtitle, .golf-subtitle {
    font-size: 19px;
  }

  .f-subtitle-2 {
    font-size: 16px;
  }
  
  .facilities-about, .golf-about {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 40px;
  }
  
  .f1-table-title, .f2-table-title {
    font-size: 16px;
  }
  
  .f2-table-title {
    width: 340px;
  }
  
  .table-1{
    width: 320px;
  }
  
  .table-2, .table-footer {
    width: 340px;
  }
  
  .table-1 th, .table-2 th {
    padding: 7px 10px 7px 10px;
    font-size: 14px;
  }
  
  span {
    font-size: 13px;
  }
}


@media (max-width: 600px) {
  .title-logo-wrap {
    padding: 0px 70px 50px 70px;
    justify-content: center;
  }

  .sports-logo-1 {
    padding-top: 100px;
    width: 35%;
  }

  .facilities-upper-part {
    flex-direction: column;
    padding: 0px 0px 40px 0px;
    align-items: center;
  }
  
  .facilities-lower-part {
    flex-direction: column;
    padding: 50px 0px 30px 0px;
    align-items: center;
  }

  .facilities-upper-part-1, .facilities-lower-part-1 {
    align-items: center;
    width: 100%;
  }

  .facilities-upper-part-2, .facilities-lower-part-2 {
    align-items: center;
    width: 100%;
  }

  .facilities-titles, .golf-titles {
    width: 100%;
    text-align: center;
    padding: 0px 50px 0px 50px;
  }
  
  .facilities-and-amenities {
    font-size: 26px;
    text-align: center;
  }

  .f1-table-container, .f2-table-container {
    margin-top: 4px;
    margin-bottom: 19px;
  }
  
  .sports-photo, .golf-rates-photo {
    margin-bottom: 9px;
  }
  
  .sports-facilities, .rates-title, .golf-facilities ,.golf-rates-title {
    font-size: 19px;
  }
  
  .facilities-subtitle, .golf-subtitle {
    font-size: 16px;
  }
  
  .f1-table-title, .f2-table-title {
    font-size: 16px;
  }
  
  .f2-table-title {
    width: 90%;
  }
  
  .table-1{
    width: 100%;
  }
  
  .table-2, .table-footer {
    width: 100%;
  }

  .tennis-photo, .clubhouse-photo {
    margin-top: 20px;
  }
  
  .table-1 th, .table-2 th {
    padding: 7px 7px 7px 7px;
    font-size: 13px;
  }
  
  span {
    font-size: 12px;
  }
}


@media (max-width: 395px) {
  .facilities-titles, .golf-titles {
    padding: 0px 20px 0px 20px;
  }
  
  .facilities-and-amenities {
    font-size: 26px;
  }

  .table-1 th, .table-2 th {
    padding: 5px 5px 5px 5px;
    font-size: 11px;
  }
  
  span {
    font-size: 11px;
  }
}
